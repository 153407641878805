import request from '@/utils/request'


// 查询项目阶段问题列表
export function listStageQuestion(query) {
  return request({
    url: '/biz/project-stage-question/list',
    method: 'get',
    params: query
  })
}

// 查询项目阶段问题分页
export function pageStageQuestion(query) {
  return request({
    url: '/biz/project-stage-question/page',
    method: 'get',
    params: query
  })
}

// 查询项目阶段问题详细
export function getStageQuestion(data) {
  return request({
    url: '/biz/project-stage-question/detail',
    method: 'get',
    params: data
  })
}

// 新增项目阶段问题
export function addStageQuestion(data) {
  return request({
    url: '/biz/project-stage-question/add',
    method: 'post',
    data: data
  })
}

// 修改项目阶段问题
export function updateStageQuestion(data) {
  return request({
    url: '/biz/project-stage-question/edit',
    method: 'post',
    data: data
  })
}

// 删除项目阶段问题
export function delStageQuestion(data) {
  return request({
    url: '/biz/project-stage-question/delete',
    method: 'post',
    data: data
  })
}
